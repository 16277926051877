import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface AuthModalStyles extends Styles {
    title: TextStyle
    subtitle: TextStyle
    image: ImageStyle
    btnTitle: TextStyle
    phoneNumber: TextStyle
    input: ViewStyle
    inputText: TextStyle
}

export const sectionStyles: StyleFunction<AuthModalStyles> = ({ theme }) => {
    return {
        inputText: {
            ...theme.font.verificationCodeCardInput,
            textAlign: "center",
            height: 50,
        },
        input: {
            height: 48,
            width: 150,
            textAlign: "center",
            justifyContent: "center",
        },
        title: {
            ...theme.font.verificationCodeCardTitle,
        },
        subtitle: {
            ...theme.font.verificationCodeCardSubtitle,
        },
        phoneNumber: {
            ...theme.font.verificationCodeCardPhoneNumber,
        },
        image: {
            height: 130,
            width: 176,
            marginBottom: 10,
        },
        btnTitle: {
            ...theme.font.verificationCodeButton,
        },
        WEB: {
            title: {
                fontKerning: "none",
            },
        },
        NATIVE: {
            input: {
                height: 58,
                width: 176,
            },
        },
    }
}
