import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, TextStyle, ViewStyle } from "react-native"

export interface AuthModalStyles extends Styles {
    title: TextStyle
    subtitle: TextStyle
    message: TextStyle
    image: ImageStyle
    input: ViewStyle
    btnTitle: TextStyle
}

export const sectionStyles: StyleFunction<AuthModalStyles> = ({ theme }) => {
    return {
        title: {
            ...theme.font.phoneNumberTitle,
        },
        subtitle: {
            ...theme.font.phoneNumberSubtitle,
            marginTop: 5,
        },
        message: {
            ...theme.font.phoneNumberBottomMessage,
            marginTop: 5,
        },
        image: {
            height: 130,
            width: 176,
            marginTop: 10,
            marginBottom: 10,
        },
        input: {
            ...theme.font.phoneNumberCardTextActive,
            textAlign: "center",
            height: 48,
            marginHorizontal: "auto",
            marginTop: 20,
            marginBottom: 10,
            borderWidth: 1,
            borderColor: "#ddd",
            width: 300,
            borderRadius: 10,
            paddingHorizontal: 10,
            backgroundColor: "#fff",
        },
        btnTitle: {
            ...theme.font.phoneNumberCardButton,
        },
        MOBILE: {
            input: {
                ...theme.shadow.phoneNumberInputShadow.web,
                width: 250,
                outlineStyle: "none",
            },
        },
        WEB: {
            title: {
                fontKerning: "none",
            },
            input: {
                ...theme.shadow.phoneNumberInputShadow.web,
                outlineStyle: "none",
            },
        },
        NATIVE: {
            input: {
                ...theme.shadow.phoneNumberInputShadow.native,
                marginTop: 15,
                marginBottom: 10,
            },
        },
    }
}
