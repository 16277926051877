import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface SharePollModalStyles extends Styles {
    content: ViewStyle
    title: TextStyle
    phoneTitle: TextStyle
    phoneInput: ViewStyle
    subtitle: TextStyle
    iconsWrapper: ViewStyle
    sendButton: ViewStyle
    sendButtonTitle: TextStyle
    shareLinkWrapper: ViewStyle
    phoneInputWrapper: ViewStyle
    spinner: ViewStyle
    spinnerContainer: ViewStyle
    spinnerWrapper: ViewStyle
}

export const sharePollModalStyles: StyleFunction<SharePollModalStyles> = ({
    theme,
    width,
}) => {
    return {
        content: {
            justifyContent: "center",
            paddingHorizontal: 27,
            paddingBottom: 42,
        },
        title: {
            ...theme.font.sharePollTitle,
            textAlign: "center",
        },
        subtitle: {
            ...theme.font.sharePollSubtitle,
            textAlign: "center",
            paddingTop: 10,
            paddingHorizontal: 20,
        },
        phoneTitle: {
            ...theme.font.sharePollPhoneTitle,
            paddingBottom: 10,
        },
        phoneInputWrapper: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
        },
        phoneInput: {
            ...theme.font.phoneNumberCardTextActive,
            textAlign: "center",
            height: 35,
            width: 226,
            borderWidth: 1,
            borderColor: "#ddd",
            borderRadius: 10,
            backgroundColor: theme.color.phoneInput,
            fontSize: 18,
            marginRight: 15,
        },
        iconsWrapper: {
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
            paddingHorizontal: 30,
            paddingTop: 35,
            paddingBottom: 5,
        },
        sendButton: {
            width: 100,
        },
        sendButtonTitle: {
            fontSize: 16,
        },
        shareLinkWrapper: {
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 26,
            paddingTop: 30,
        },
        spinnerWrapper: {
            width: width,
            paddingVertical: 100,
        },

        spinnerContainer: {
            flex: 1,
            justifyContent: "center",
            width: "100%",
        },
        spinner: {
            width: 80,
            height: 80,
            alignSelf: "center",
        },
        WEB: {
            phoneInput: {
                ...theme.shadow.phoneNumberInputShadow.web,
            },
            shareLinkWrapper: {
                paddingTop: 5,
            },
            spinnerWrapper: {
                width: 325,
                paddingVertical: 100,
            },
        },
        MOBILE: {
            phoneInput: {
                ...theme.shadow.phoneNumberInputShadow.web,
            },
            iconsWrapper: {
                paddingHorizontal: 0,
            },
        },
        NATIVE: {
            content: {
                maxHeight: 400,
            },
            iconsWrapper: {
                paddingHorizontal: 0,
            },
        },
    }
}
