import { ImageSourcePropType } from "react-native"
import { Icons } from "@assets/icons"
import { EncodedImages } from "@assets/images/encodedImages"
import { FontSource } from "expo-font"

export type base64Image = string

/* eslint-disable no-undef */
interface AvailableColors {
    // App // items used throughout the app globally
    skeletonDark: string
    skeletonLight: string
    skeletonHighlight: string
    appBackgroundMobile: string
    appBackgroundDesktop: string
    appBackgroundCardDesktop: string
    modalBackground: string
    modalOverlay: string
    modalDivider: string
    divider: string
    checkBox: string
    navHeaderBackground: string

    // input in box variant
    inputBoxColor: string
    inputBoxBorderColor: string
    inputPlaceholderColor: string

    // Bottom Navigation // notification for the user after they have selected an option or made an update
    bottomNavigationBackgroundColor: string
    bottomNavigationFlagBackground: string

    // Poll Title //
    titleContainer: string
    pollTitleCursor: string
    pollTitleDivider: string

    // Help Text // this is floating text on the set poll title screen and add options screens
    helpTextPrimary: string
    helpTextSecondary: string

    // CTAs // the primary tappable buttons and different states of each
    ctaPrimaryInactiveBackground: string // e.g. submit button when disabled
    ctaPrimaryActiveBackground: string // e.g. submit button when enabled
    ctaPrimarySuccessBackground: string // e.g. after copying link on share poll screen
    ctaPrimaryText: string // e.g. submit button title
    ctaPrimaryDisabledText: string // e.g. submit button title
    ctaSecondaryInactiveBackground: string // e.g. add option button
    ctaSecondaryActiveBackground: string // e.g. add option button
    ctaSecondaryText: string // e.g. add option button title
    ctaSecondaryDisabledText: string // e.g. add option button title
    ctaSecondaryTextFailure: string // e.g. error messages
    ctaFloatingText: string // e.g. Cancel button when editing user name in profile
    ctaFloatingDisabledText: string // e.g. Cancel button when editing user name in profile
    ctaDelete: string // e.g. Remove/Delete button on bottom navbar

    // Option Cards // Option cards both in draft and active states
    optionCardBackground: string
    optionCardTextCursor: string
    optionCardDateTimeText: string // second line of text for date time autocomplete cards
    optionCardProgressBarBackground: string
    optionCardProgressBarFill: string
    optionCardLinkText: string // domain text color e.g. airbnb.com
    optionCardLockedIcon: string
    optionCardRemoveIcon: string
    optionCardDeleteIcon: string
    optionCardGroupIcon: string
    optionCardCheckbox: string
    optionCardSkeletonBackground: string

    // Profile Page //
    profileAvatarBackground: string // set

    // Profile Page User Info // User specific info on the profile page
    profileUpdateNameTextCursor: string

    // Poll Default Settings //
    profileSettingsCardBackground: string

    // Drawers/Modal //
    notificationPollUrlCardBackground: string // e.g. Card for Poll URL to copy in share drawer

    // Customer info modal
    customerInfoModalDivider: string
    customerInfoModalItemIcon: string
    customerInfoModalItemIconContainer: string

    // Poll Send Settings // Individual controls before the poll creator sends the poll
    pollSettingCard: string
    pollSettingText: string

    pollOverlay: string

    // Share Buttons //
    shareViaAppButtonBackgroundColor: string // circle with app icon it in for sharing via SMS, email, WhatsApp, Facebook, etc.
    shareViaAppButtonText: string // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.

    // Verify Phone Number // Flow for users to enter phone numbers and verification codes to sign in
    phoneNumberCardBackground: string
    phoneNumberCardTextPlaceHolder: string
    phoneNumberCardTextActive: string
    verificationCodeCardBackground: string

    pollHistoryDivider: string
    pollHistoryIcon: string

    pollListCopyBannerBackground: string
    pollListCopyBannerIconBackground: string
    pollListCopyBannerIcon: string

    step2SkeletonCardTitle: string
    step2SkeletonCardSubtitle: string
    step2FilterDivider: string

    primaryIcon: string
    secondaryIcon: string
}

interface FontProps {
    fontFamily: string
    fontSize: number
    lineHeight: number
    color?: string
}

interface AvailableFonts {
    // App // items used throughout the app globally
    phoneButtonText: FontProps
    phoneButtonIcon: FontProps
    backButtonText: FontProps
    backButtonIcon: FontProps
    errorText: FontProps
    textUnderImage: FontProps

    // Top Logo
    logoHeader: FontProps // -< added >-
    logoHeaderMobile: FontProps // -< added >-
    logoSubheader: FontProps // -< added >-

    // Powered by Logo
    poweredByHeader: FontProps
    poweredBySubheader: FontProps

    input: FontProps // -< added >-

    // Bottom Navigation Bar //
    bottomNavigationFlagText: FontProps // notification for the user after they have selected an option or made an update

    // Poll Title //
    createPollTitleWeb: FontProps
    createPollTitleMobile: FontProps
    createPollSubTitle: FontProps
    createPollHeaderText: FontProps
    pollTitlePlaceHolder: FontProps
    pollTitleActiveWeb: FontProps
    pollTitleActiveMobile: FontProps

    // Help Text //
    helpTextPrimary: FontProps
    helpTextSecondary: FontProps

    // Example Poll Title //
    examplePollText: FontProps // text that floats above the suggestions or auotcompletes above the keyboard

    // CTAs //
    ctaPrimaryText: FontProps // e.g. submit button
    ctaSecondaryText: FontProps // e.g. add option button
    ctaFloatingText: FontProps // e.g. cancel when editing name in profile

    navbarDefaultIcon: FontProps
    navbarErrorIcon: FontProps

    // Option Cards //
    optionCardTextPlaceHolder: FontProps
    optionCardInputText: FontProps
    optionCardTextActive: FontProps
    optionCardDateTimeText: FontProps
    optionCardTextVoteCount: FontProps
    optionCardLinkTitle: FontProps
    optionCardLinkSubtitle: FontProps
    optionCardLink: FontProps

    // Default Card template //
    defaultCardTemplateTitle: FontProps
    defaultCardTemplateSubtitle: FontProps
    defaultCardTemplateDescription: FontProps
    defaultCardTemplateLink: FontProps

    // Profile Page //
    profileSectionTitle: FontProps
    profileLinkText: FontProps
    profileLinkIcon: FontProps
    profileLogOutText: FontProps
    versionText: FontProps

    //  Home Page //
    homePageTitle: FontProps
    homePageSubtitle: FontProps
    //homeInfoLine: FontProps not in use!
    //homeInfoLineGrey: FontProps not in use!
    homeGreeting: FontProps
    homeName: FontProps
    pollHistoryHeader: FontProps
    pollHistoryEmptyTitle: FontProps
    pollHistoryItem: FontProps

    // Consent Page //
    consentPageTitle: FontProps
    consentPageSubtitle: FontProps
    consentGreeting: FontProps
    consentInfoLine: FontProps
    consentPageAgreeButtonTitle: FontProps
    // consentInfoLineGrey: FontProps  not in use!

    // Get App Page //
    getAppTitle: FontProps
    getAppSubtitle: FontProps
    getAppQrTitle: FontProps

    // Profile Page User Info //
    profileAvatarIcon: FontProps
    profileAvatarIconSmall: FontProps
    profileAvatarIconTiny: FontProps
    profileAvatarLetter: FontProps
    profileAvatarLetterSmall: FontProps
    profileAvatarLetterTiny: FontProps
    profileUserNameText: FontProps
    profileUserNameIcon: FontProps
    profilePhoneText: FontProps
    profilePhoneIcon: FontProps

    // drawer/modal
    modalCloseIcon: FontProps

    //Logout modal
    logoutModalTitle: FontProps //perhaps notificationPrimaryText
    logoutModalSubtile: FontProps
    logoutModalSubtileDesktop: FontProps
    logoutModalLogoutButtonText: FontProps
    logoutModalSmallLogoutButtonText: FontProps
    logoutModalCancelButtonText: FontProps
    logoutModalSmallCancelButtonText: FontProps

    //Logout modal
    discardModalTitle: FontProps //perhaps notificationPrimaryText
    discardModalSubtile: FontProps
    discardModalSubtileDesktop: FontProps
    discardModalLogoutButtonText: FontProps
    discardModalSmallLogoutButtonText: FontProps
    discardModalCancelButtonText: FontProps
    discardModalSmallCancelButtonText: FontProps

    // Poll Default Settings //
    profileSettingsCardPrimaryText: FontProps
    profileSettingsCardPrimaryIcon: FontProps
    profileSettingsCardSecondaryText: FontProps

    // Drawer/Modals //
    notificationPrimaryText: FontProps
    notificationSecondaryText: FontProps
    notificationTextMeSectionTitle: FontProps
    notificationPollUrlText: FontProps // e.g. Poll URL to copy in share drawer

    // Customer info Modal
    customerInfoModalHeader: FontProps
    customerInfoModalItemTitle: FontProps
    customerInfoModalItemSubtitle: FontProps

    // See Who Voted //
    voterListSectionHeader: FontProps
    voterListNamesText: FontProps
    voterListNumbersText: FontProps
    voterListTitle: FontProps

    // Poll Send Settings //
    pollSettingsText: FontProps // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.

    // Share Icons //
    shareViaAppButtonText: FontProps

    // Verify Phone Number //
    phoneNumberCardButton: FontProps
    phoneNumberCardTextPlaceHolder: FontProps
    phoneNumberCardTextActive: FontProps
    phoneNumberCardTextBottom: FontProps
    verificationCodeCardTextPlaceholder: FontProps
    verificationCodeCardInput: FontProps
    verificationCodeCardTextActive: FontProps
    verificationCodeCardTitle: FontProps
    verificationCodeCardSubtitle: FontProps
    verificationCodeCardPhoneNumber: FontProps
    verificationCodeResendCode: FontProps
    verificationCodeButton: FontProps
    phoneNumberTitle: FontProps
    phoneNumberSubtitle: FontProps
    phoneNumberBottomMessage: FontProps

    // add option
    addOptionGroupIcon: FontProps

    // Vote Share Links
    voteShareLinkText: FontProps
    voteShareTitle: FontProps
    voteShareStepText: FontProps
    voteShareShareVoteText: FontProps
    voteShareCopyText: FontProps
    voteShareCopyIcon: FontProps
    // Voter Add Name

    voterAddNameTitle: FontProps
    voterAddNameSubtitle: FontProps

    // Preview image
    questionTitle: FontProps
    optionTitle: FontProps
    optionSubtitle: FontProps
    votesCountText: FontProps

    // Create Poll Send Settings
    createPollSendSettingsSubtitle: FontProps
    createPollSendSettingsOption: FontProps
    createPollSendSettingsCheckIcon: FontProps

    postVoteDownloadTitleText: FontProps
    postVoteDownloadSubtitleText: FontProps

    pollListCopyBannerTitle: FontProps
    pollListCopyBannerSubtitle: FontProps

    selectPollCounter: FontProps
    selectPollCounterError: FontProps
    selectPollCounterText: FontProps

    toolTipSubmitTitleText: FontProps
    toolTipSubmitSubtitleText: FontProps

    step2FilterAll: FontProps
    step2FilterNone: FontProps
    step2OptionsSelectedCounterValid: FontProps
    step2OptionsSelectedCounterInvalid: FontProps
    step2OptionsCounterText: FontProps

    //Share poll modal
    sharePollTitle: FontProps
    sharePollPhoneTitle: FontProps
    sharePollSubtitle: FontProps
}

interface ShadowProp {
    color: string
    offset: { width: number; height: number }
    blur: number
    elevation: number
}

interface Shadow {
    web: {
        boxShadow: string
    }
    native: {
        shadowColor: string
        shadowOpacity: number
        shadowRadius: number
        elevation: number
        shadowOffset: { width: number; height: number }
    }
}

interface AvailableShadows {
    // OLD shadows TBR//
    bottomNav: ShadowProp
    button: ShadowProp
    errorModal: ShadowProp

    input: Shadow

    // App //
    pollHeader: Shadow
    appCardDesktopExterior: Shadow
    shareLinkShadow: Shadow

    // see who voted
    seeWhoVoted: Shadow

    // Bottom Navigation //
    bottomNavigationCard: Shadow
    bottomNavigationFlagBackground: Shadow

    // CTAs //
    cta: Shadow

    // Option Card //
    optionCard: Shadow

    // Vote Card //
    voteCard: Shadow

    // Profile User Info //
    profileNameFieldInteriorShadow: Shadow

    // Poll Default Settings //
    profileSettingsCard: Shadow

    // Modals / Drawer //
    modal: Shadow
    pollUrlCard: Shadow

    // Poll Send Settings //
    pollSettingsCard: Shadow

    // Share Drawer //
    shareViaAppButton: Shadow

    // Verify Phone Number //
    phoneNumberCardBackground: Shadow
    verificationCodeCardBackground: Shadow
    phoneNumberInputShadow: Shadow
    imageWithShadowContainer: Shadow
    errorBoxShadow: Shadow
}

interface AvailableBorderRadiuses {
    button: number
    modal: number
    errorModal: number
    bottomNav: number
    CTA: number
    pills: number
    pollSendSetting: number
    drawer: number
    input: number
}

interface AvailableCornerRadiuses {
    // Bottom Navigation //
    bottomNavigationCard: number
    bottomNavigationFlagBackground: number

    // Example Poll Card //
    examplePollCard: number

    // CTAs //
    cta: number

    // input text //
    input: number

    // Option Card //
    optionCard: number

    // Poll Default Settings Card //
    profileSettingsCard: number

    // Modal //
    modal: number

    // Drawer //
    drawer: number

    // Poll Send Settings //
    pollSettingsCard: number

    // Verify Phone Number //
    phoneNumberCardBackground: number
    verificationCodeCardBackground: number
}

interface AvailableImages {
    // App //
    appLogo: ImageSourcePropType
    previewImageBackground: ImageSourcePropType | null
    previewImageLogo?: base64Image
    previewImageCard: ImageSourcePropType
    appBackgroundMobile: string
    appBackgroundDesktop: string
    splashImage: ImageSourcePropType
    homeScreenImage: ImageSourcePropType
    consentScreenImage: ImageSourcePropType
    backIcon: string
    checkboxChecked: string
    checkboxUnchecked: string
    pollHistoryHeader: ImageSourcePropType
    pollHistoryEmpty: ImageSourcePropType
    imagePlaceholder: base64Image

    closeX: string
    linkOut: string

    // Bottom Navigation //
    navBarTrash: string
    navBarProfile: string
    navBarEdit: string
    navBarHome: string
    navBarShare: string
    navBarInfo: string

    // Example Poll Card //
    examplePollSuggestionButton: string
    examplePollAddButton: string
    examplePollDateAndTime: string

    // Option Card //
    optionCardVoteCounterActive: string
    optionCardVoteCounterInactive: string
    optionCardLocked: string
    optionCardRemove: string
    optionCardLink: string
    optionCardAddedConfirmation: string
    optionCardCancel: string
    optionCardDrag: string

    // Tap to Paste Button //

    // Profile //
    userNameEdit: string
    profileAvatar: string
    profileAuthenticateButtonIcon: string
    profilePhoneNumberIcon: string
    profileLinkOutIcon: string
    profileShareFeedbackIcon: string
    userSettingsEditPoll: string
    userSettingsMultiVote: string
    userSettingsSeeWhoVoted: string

    // Modal/Drawer //
    pollSendSettingsGraphic: base64Image
    pollLockedGraphic: base64Image
    addUserNameGraphic: base64Image
    loadPollFailedGraphic: base64Image
    launchPollFailedGraphic: base64Image
    updatePollFailedGraphic: base64Image
    updatePollCrossErrorGraphic: base64Image
    missingOptionsGraphic: base64Image
    pollAnonymousGraphic: string
    shareVoteGraphic: base64Image
    userAuthenticateGraphic: base64Image
    pollsAdvertise: base64Image
    rememberSubmit: base64Image

    // Share Icons //
    shareViaSMS: ImageSourcePropType
    shareViaEmail: ImageSourcePropType
    shareViaMessenger: ImageSourcePropType
    shareViaWhatsApp: ImageSourcePropType
    shareViaIMessages: ImageSourcePropType
    shareCopyButtonCheckmark: string

    // Get App Screen
    getAppScreenImage: base64Image
}
interface AvailableLotties {
    checkmark: string
    checkmarkDone: string
    checkBox: string
    whiteSpinner: string
    spinner: string
    trashCan: string
    createPollSuccess: string
    signInSuccess: string
    voteSuccess: string
    success: string
}

export type FontFiles = Record<string, FontSource>

export interface Theme {
    color: AvailableColors
    font: AvailableFonts
    shadow: AvailableShadows
    cornerRadius: AvailableCornerRadiuses
    image: AvailableImages
    layout: any
    lottie: AvailableLotties

    //old stuff to be removed
    borderRadius: AvailableBorderRadiuses
    fontFiles: FontFiles
}

export interface ThemeOverrides {
    color?: Partial<AvailableColors>
    font?: Partial<AvailableFonts>
    shadow?: Partial<AvailableShadows>
    cornerRadius?: Partial<AvailableCornerRadiuses>
    image?: Partial<AvailableImages>
    layout?: Partial<any>
    lottie?: Partial<AvailableLotties>

    //old stuff to be removed
    borderRadius?: AvailableBorderRadiuses
    fontFiles?: FontFiles
}

export interface FontPalette {
    regular: string
    semiBold: string
    bold: string

    pollsRegular: "sf-pro-display"
    pollsSemiBold: "sf-pro-display-semibold"
    pollsBold: "sf-pro-display-bold"
}

export interface ColorPalette {
    //Colors
    primary: string
    secondary: string
    success: string
    primaryText: string
    secondaryText: string
    background: string

    black: string
    white: string
    red: string
    blue: string
    darkGrey: string
    grey: string
    lightGrey: string
    flag: string
    textColor: string
    lightBlue: string

    //Polls Colors
    pollPrimaryTextColor: string
}

export const defaultColorPalette: ColorPalette = {
    primary: "#007AFE",
    secondary: "#4DA1FF",
    success: "#05CB4F",
    primaryText: "#343E57",
    secondaryText: "#676767",
    background: "#E0EAFD",

    black: "#000000",
    white: "#FFFFFF",
    red: "#FE281C",
    blue: "#007AFE",
    darkGrey: "#9B9B9B",
    grey: "#D8D8D8",
    lightGrey: "#EBEBEB",
    flag: "#FA6400",
    textColor: "#535C71",
    lightBlue: "#EFF5FF",

    //Polls Colors
    pollPrimaryTextColor: "#343E57",
}

export const createTheme = (options: {
    fontPalette: FontPalette
    fontFiles: FontFiles
    colorPalette: ColorPalette
    overrides: ThemeOverrides
    fontSizeMultiplier: number
    lineHeightMultiplier: number
}): Theme => {
    const {
        fontPalette,
        fontFiles,
        colorPalette,
        overrides,
        fontSizeMultiplier,
        lineHeightMultiplier,
    } = options

    const theme: Theme = {
        fontFiles: {
            "sf-pro-display": require("@assets/fonts/SFProDisplay-Regular.ttf"),
            "sf-pro-display-semibold": require("@assets/fonts/SFProDisplay-Semibold.ttf"),
            "sf-pro-display-bold": require("@assets/fonts/SFProDisplay-Bold.ttf"),
            arial: require("@assets/fonts/Arial.ttf"),
            ...fontFiles,
        },
        color: {
            // App // items used throughout the app globally
            skeletonDark: colorPalette.pollPrimaryTextColor,
            skeletonLight: colorPalette.grey,
            skeletonHighlight: colorPalette.white,
            appBackgroundMobile: colorPalette.white, // colorPalette.white,
            appBackgroundDesktop: colorPalette.white,
            appBackgroundCardDesktop: colorPalette.white,
            modalBackground: colorPalette.white,
            modalOverlay: colorPalette.white,
            modalDivider: colorPalette.lightGrey,
            divider: colorPalette.lightGrey,
            navHeaderBackground: "transparent",

            inputBoxColor: colorPalette.white,
            inputBoxBorderColor: colorPalette.blue,
            inputPlaceholderColor: colorPalette.lightGrey,

            // Bottom Navigation // notification for the user after they have selected an option or made an update
            bottomNavigationBackgroundColor: colorPalette.white,
            bottomNavigationFlagBackground: colorPalette.flag,

            // Poll Title //
            titleContainer: colorPalette.white,
            pollTitleCursor: colorPalette.blue,
            pollTitleDivider: colorPalette.lightGrey,

            // Help Text // this is floating text on the set poll title screen and add options screens
            helpTextPrimary: colorPalette.darkGrey,
            helpTextSecondary: colorPalette.darkGrey,

            // CTAs // the primary tappable buttons and different states of each
            ctaPrimaryInactiveBackground: colorPalette.darkGrey, // e.g. submit button when disabled
            ctaPrimaryActiveBackground: colorPalette.primary, // e.g. submit button when enabled
            ctaPrimarySuccessBackground: colorPalette.success, // e.g. after copying link on share poll screen
            ctaPrimaryText: colorPalette.white, // e.g. submit button title
            ctaPrimaryDisabledText: colorPalette.white, // same button in disabled state
            ctaSecondaryInactiveBackground: colorPalette.white, // e.g. add option button
            ctaSecondaryActiveBackground: colorPalette.white,
            ctaSecondaryText: colorPalette.primary, // e.g. add option button title
            ctaSecondaryDisabledText: colorPalette.lightGrey, // e.g. add option button title
            ctaSecondaryTextFailure: colorPalette.red, // e.g. error messages
            ctaFloatingText: colorPalette.primary, // e.g. Cancel button when editing user name in profile
            ctaFloatingDisabledText: colorPalette.lightGrey, // e.g. Cancel button when editing user name in profile
            ctaDelete: colorPalette.red,

            // Option Cards // Option cards both in draft and active states
            optionCardBackground: colorPalette.white,
            optionCardTextCursor: colorPalette.primary,
            optionCardDateTimeText: colorPalette.primaryText, // second line of text for date time autocomplete cards
            optionCardProgressBarBackground: colorPalette.lightGrey,
            optionCardProgressBarFill: colorPalette.primary,
            optionCardLinkText: colorPalette.primary, // domain text color e.g. airbnb.com
            optionCardLockedIcon: colorPalette.darkGrey,
            optionCardRemoveIcon: colorPalette.red,
            optionCardDeleteIcon: colorPalette.darkGrey,
            optionCardGroupIcon: colorPalette.lightGrey,
            optionCardCheckbox: colorPalette.primary,
            optionCardSkeletonBackground: colorPalette.darkGrey,

            // Profile Page //
            profileAvatarBackground: colorPalette.primary,
            checkBox: colorPalette.primary,

            // Profile Page User Info // User specific info on the profile page
            profileUpdateNameTextCursor: colorPalette.primary,

            // Poll Default Settings //
            profileSettingsCardBackground: colorPalette.white,

            // Drawers/Modal //
            notificationPollUrlCardBackground: colorPalette.white, // e.g. Card for Poll URL to copy in share drawer

            // Customer info modal
            customerInfoModalDivider: colorPalette.lightGrey,
            customerInfoModalItemIcon: colorPalette.primary,
            customerInfoModalItemIconContainer: colorPalette.background,

            // Poll Send Settings // Individual controls before the poll creator sends the poll
            pollSettingCard: colorPalette.white,
            pollSettingText: colorPalette.primaryText,

            pollOverlay: colorPalette.white,

            // Share Buttons //
            shareViaAppButtonBackgroundColor: colorPalette.white, // circle with app icon it in for sharing via SMS, email, WhatsApp, Facebook, etc.
            shareViaAppButtonText: colorPalette.darkGrey, // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.

            // Verify Phone Number // Flow for users to enter phone numbers and verification codes to sign in
            phoneNumberCardBackground: colorPalette.white,
            phoneNumberCardTextPlaceHolder: colorPalette.darkGrey,
            phoneNumberCardTextActive: colorPalette.primaryText,
            verificationCodeCardBackground: colorPalette.white,

            pollHistoryDivider: colorPalette.background,
            pollHistoryIcon: colorPalette.primary,

            pollListCopyBannerBackground: colorPalette.lightBlue,
            pollListCopyBannerIconBackground: colorPalette.white,
            pollListCopyBannerIcon: colorPalette.primary,

            step2SkeletonCardTitle: colorPalette.darkGrey,
            step2SkeletonCardSubtitle: colorPalette.lightGrey,
            step2FilterDivider: colorPalette.lightGrey,

            primaryIcon: colorPalette.success,
            secondaryIcon: colorPalette.secondary,
        },

        font: {
            // App // items used throughout the app globally
            phoneButtonText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },
            phoneButtonIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.primary,
            },
            backButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.blue,
            },
            backButtonIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.blue,
            },
            errorText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.red,
            },
            textUnderImage: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.darkGrey,
            },
            logoHeader: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 23,
                lineHeight: lineHeightMultiplier * 23,
                color: colorPalette.white,
            },
            logoHeaderMobile: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.pollPrimaryTextColor,
            },
            logoSubheader: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.white,
            },

            poweredByHeader: {
                fontFamily: fontPalette.pollsBold,
                fontSize: fontSizeMultiplier * 17,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.white,
            },
            poweredBySubheader: {
                fontFamily: fontPalette.pollsSemiBold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.white,
            },

            input: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.primaryText,
            },

            // Bottom Navigation Bar //
            bottomNavigationFlagText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.white,
            }, // notification for the user after they have selected an option or made an update

            // Poll Title //
            createPollTitleWeb: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 26,
                color: colorPalette.primaryText,
            },
            createPollTitleMobile: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.primaryText,
            },
            createPollSubTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.primaryText,
            },
            createPollHeaderText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.primaryText,
            },
            pollTitlePlaceHolder: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 32,
                lineHeight: lineHeightMultiplier * 32,
                color: colorPalette.darkGrey,
            },
            pollTitleActiveWeb: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 26,
                color: colorPalette.primaryText,
            },
            pollTitleActiveMobile: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.primaryText,
            },

            // Help Text //
            helpTextPrimary: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.darkGrey,
            },
            helpTextSecondary: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.darkGrey,
            },

            // Example Poll Title //
            examplePollText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            }, // text that floats above the suggestions or auotcompletes above the keyboard

            // CTAs //
            ctaPrimaryText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 19,
            }, // e.g. submit button
            ctaSecondaryText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            }, // e.g. add option button
            ctaFloatingText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.primary,
            }, // e.g. cancel when editing name in profile
            navbarDefaultIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.primary,
            },
            navbarErrorIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.red,
            },

            // Option Cards //
            optionCardTextPlaceHolder: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14.5,
                lineHeight: lineHeightMultiplier * 14.5,
                color: colorPalette.darkGrey,
            },
            optionCardInputText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 15,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.primaryText,
            },
            optionCardTextActive: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14.5,
                lineHeight: lineHeightMultiplier * 14.5,
                color: colorPalette.primaryText,
            },
            optionCardDateTimeText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },

            optionCardTextVoteCount: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.primaryText,
            },

            optionCardLinkTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: fontSizeMultiplier * 16,
                color: colorPalette.pollPrimaryTextColor,
            },

            optionCardLinkSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 11,
                lineHeight: fontSizeMultiplier * 11,
                color: colorPalette.darkGrey,
            },

            optionCardLink: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: fontSizeMultiplier * 12,
                color: colorPalette.blue,
            },

            // default card template
            defaultCardTemplateTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.pollPrimaryTextColor,
            },
            defaultCardTemplateSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.black,
            },
            defaultCardTemplateDescription: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.darkGrey,
            },
            defaultCardTemplateLink: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 11,
                lineHeight: lineHeightMultiplier * 11,
                color: colorPalette.blue,
            },

            // Home Page //
            homePageTitle: {
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 35,
                lineHeight: lineHeightMultiplier * 35,
                fontFamily: fontPalette.bold,
            },
            homePageSubtitle: {
                color: colorPalette.primary,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                fontFamily: fontPalette.bold,
            },
            homeName: {
                color: colorPalette.pollPrimaryTextColor,
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 35,
                lineHeight: lineHeightMultiplier * 35,
            },
            homeGreeting: {
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 23,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 23,
            },
            pollHistoryHeader: {
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                fontFamily: fontPalette.bold,
            },
            pollHistoryEmptyTitle: {
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                fontFamily: fontPalette.regular,
            },
            pollHistoryItem: {
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                fontFamily: fontPalette.semiBold,
            },

            // Consent Page //
            consentPageTitle: {
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
                fontFamily: fontPalette.bold,
            },
            consentPageSubtitle: {
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                fontFamily: fontPalette.semiBold,
            },
            consentGreeting: {
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 23,
                fontSize: fontSizeMultiplier * 23,
                color: colorPalette.darkGrey,
            },
            consentInfoLine: {
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 13,
                fontFamily: fontPalette.regular,
            },
            consentPageAgreeButtonTitle: {
                fontSize: 30,
                lineHeight: 32,
                fontFamily: fontPalette.bold,
                color: colorPalette.primary,
            },

            // Get App Page //
            getAppTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
                color: colorPalette.pollPrimaryTextColor,
            },
            getAppSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.lightGrey,
            },
            getAppQrTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.blue,
            },

            // Profile Page //
            profileSectionTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 22,
                color: colorPalette.darkGrey,
            },
            profileLinkText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primaryText,
            },
            versionText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.primaryText,
            },
            profileLinkIcon: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.primary,
            },
            profileLogOutText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primary,
            },

            // drawer modal
            modalCloseIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.darkGrey,
            },

            // logout modal
            //perhaps notificationPrimaryText
            logoutModalTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.primaryText,
            },
            logoutModalSubtile: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.darkGrey,
            },
            logoutModalSubtileDesktop: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },
            logoutModalLogoutButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.red,
            },
            logoutModalSmallLogoutButtonText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.red,
            },
            logoutModalCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.blue,
            },
            logoutModalSmallCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.primary,
            },

            // discard modal
            //perhaps notificationPrimaryText
            discardModalTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.primaryText,
            },
            discardModalSubtile: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.darkGrey,
            },
            discardModalSubtileDesktop: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },
            discardModalLogoutButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.red,
            },
            discardModalSmallLogoutButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.red,
            },
            discardModalCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.blue,
            },
            discardModalSmallCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.blue,
            },

            // Profile Page User Info //
            profileAvatarIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 60,
                lineHeight: lineHeightMultiplier * 68,
                color: colorPalette.white,
            },
            profileAvatarIconSmall: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 40,
                color: colorPalette.white,
            },
            profileAvatarIconTiny: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.white,
            },
            profileAvatarLetter: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 60,
                lineHeight: lineHeightMultiplier * 68,
                color: colorPalette.white,
            },
            profileAvatarLetterSmall: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 26,
                color: colorPalette.white,
            },
            profileAvatarLetterTiny: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.white,
            },
            profileUserNameText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.primaryText,
            },
            profileUserNameIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.primary,
            },
            profilePhoneText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 15,
                lineHeight: lineHeightMultiplier * 15,
                color: colorPalette.primaryText,
            },
            profilePhoneIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.primary,
            },

            // Poll Default Settings //
            profileSettingsCardPrimaryText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primaryText,
            },
            profileSettingsCardPrimaryIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primaryText,
            },
            profileSettingsCardSecondaryText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.primaryText,
            },

            // Drawer/Modals //
            notificationPrimaryText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.pollPrimaryTextColor,
            },
            notificationSecondaryText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.pollPrimaryTextColor,
            },
            notificationTextMeSectionTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            },
            notificationPollUrlText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 15,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.pollPrimaryTextColor,
            }, // e.g. Poll URL to copy in share drawer

            // Customer info modal
            customerInfoModalHeader: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
                color: colorPalette.pollPrimaryTextColor,
            },
            customerInfoModalItemTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.primaryText,
            },
            customerInfoModalItemSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.secondaryText,
            },

            // See Who Voted //
            voterListSectionHeader: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.primaryText,
            },
            voterListNamesText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            },
            voterListNumbersText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.primaryText,
            },
            voterListTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primaryText,
            },

            // Poll Send Settings //
            pollSettingsText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            }, // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.

            // Share Icons //
            shareViaAppButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
            },

            // Verify Phone Number //
            phoneNumberCardButton: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
            },
            phoneNumberCardTextPlaceHolder: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.darkGrey,
            },
            phoneNumberCardTextActive: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.primaryText,
            },
            phoneNumberCardTextBottom: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.darkGrey,
            },
            verificationCodeCardTextPlaceholder: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 30,
                color: colorPalette.darkGrey,
            },
            verificationCodeCardInput: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 30,
                color: colorPalette.primaryText,
            },
            verificationCodeCardTextActive: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.primaryText,
            },
            verificationCodeCardTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.primaryText,
            },
            verificationCodeCardSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },
            verificationCodeCardPhoneNumber: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },
            verificationCodeResendCode: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primary,
            },
            verificationCodeButton: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            },
            phoneNumberTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.primaryText,
            },
            phoneNumberSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },
            phoneNumberBottomMessage: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.darkGrey,
            },

            //ADD OPTION
            addOptionGroupIcon: {
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 17,
                fontFamily: fontPalette.regular,
                lineHeight: lineHeightMultiplier * 17,
            },

            //VOTE SECTION //
            voteShareLinkText: {
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 16,
                fontFamily: fontPalette.regular,
                lineHeight: lineHeightMultiplier * 16,
            },
            voteShareTitle: {
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 24,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 24,
            },
            voteShareStepText: {
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 24,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 24,
            },
            voteShareShareVoteText: {
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 24,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 24,
            },

            voteShareCopyText: {
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 16,
                fontFamily: fontPalette.regular,
                lineHeight: lineHeightMultiplier * 16,
            },
            voteShareCopyIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.white,
            },
            voterAddNameTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
            },
            voterAddNameSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.darkGrey,
            },

            // Preview image
            questionTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.white,
                lineHeight: lineHeightMultiplier * 46,
                fontSize: fontSizeMultiplier * 40,
            },
            optionTitle: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 40,
            },
            optionSubtitle: {
                fontFamily: fontPalette.regular,
                color: colorPalette.secondaryText,
                fontSize: fontSizeMultiplier * 28,
                lineHeight: lineHeightMultiplier * 34,
            },
            votesCountText: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
            },
            // Create Poll Send Settings
            createPollSendSettingsSubtitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.darkGrey,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            createPollSendSettingsOption: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            createPollSendSettingsCheckIcon: {
                fontFamily: "",
                color: colorPalette.success,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            postVoteDownloadTitleText: {
                fontFamily: fontPalette.bold,
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: fontSizeMultiplier * 24,
            },
            postVoteDownloadSubtitleText: {
                fontFamily: fontPalette.regular,
                color: colorPalette.textColor,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: fontSizeMultiplier * 14,
            },

            pollListCopyBannerTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.primaryText,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: fontSizeMultiplier * 16,
            },
            pollListCopyBannerSubtitle: {
                fontFamily: fontPalette.regular,
                color: colorPalette.textColor,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: fontSizeMultiplier * 12,
            },

            selectPollCounter: {
                fontFamily: fontPalette.regular,
                color: colorPalette.secondary,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: fontSizeMultiplier * 36,
            },
            selectPollCounterError: {
                fontFamily: fontPalette.regular,
                color: colorPalette.red,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: fontSizeMultiplier * 36,
            },
            selectPollCounterText: {
                fontFamily: fontPalette.regular,
                color: colorPalette.textColor,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: fontSizeMultiplier * 14,
            },

            toolTipSubmitTitleText: {
                fontFamily: fontPalette.bold,
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 36,
            },
            toolTipSubmitSubtitleText: {
                fontFamily: fontPalette.regular,
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 22,
            },

            step2FilterAll: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.blue,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            step2FilterNone: {
                fontFamily: fontPalette.regular,
                color: colorPalette.blue,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            step2OptionsSelectedCounterValid: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.blue,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
            },
            step2OptionsSelectedCounterInvalid: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.red,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
            },
            step2OptionsCounterText: {
                fontFamily: fontPalette.regular,
                color: colorPalette.blue,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },

            // Share Poll Modal
            sharePollTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
            },
            sharePollPhoneTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            },
            sharePollSubtitle: {
                fontFamily: fontPalette.regular,
                color: colorPalette.pollPrimaryTextColor,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
            },
        },
        shadow: {
            // OLD shadows TO BE REMOVED
            bottomNav: {
                color: "#A1A1A1",
                offset: { width: 0, height: 2 },
                blur: 7,
                elevation: 20,
            },
            button: {
                color: "#A1A1A1",
                offset: { width: 0, height: 2 },
                blur: 7,
                elevation: 7,
            },
            errorModal: {
                color: "#A1A1A1",
                offset: { width: 0, height: 2 },
                blur: 11,
                elevation: 20,
            },

            // App //
            input: {
                web: { boxShadow: "inset 0px 0px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 1,
                    shadowRadius: 4,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },
            pollHeader: {
                web: { boxShadow: "0px 0px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            appCardDesktopExterior: {
                web: {
                    boxShadow:
                        "0px 0px 20px rgb(0,0,0,0.2), inset 0px 0px 10px rgba(0,0,0,0.2)",
                },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },
            shareLinkShadow: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 4,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // See who voted //
            seeWhoVoted: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Bottom Navigation //
            bottomNavigationCard: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            bottomNavigationFlagBackground: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // CTAs //
            cta: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Option Card //
            optionCard: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Option Card //
            voteCard: {
                web: { boxShadow: "0px 0px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },

            // Profile User Info //
            profileNameFieldInteriorShadow: {
                web: { boxShadow: "0px 2px 10px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Poll Default Settings //
            profileSettingsCard: {
                web: { boxShadow: "0px 0px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 3,
                    shadowOffset: { width: 0, height: 0 },
                },
            },

            // Modals / Drawer //
            modal: {
                web: { boxShadow: "0px 0px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },

            pollUrlCard: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Poll Send Settings //
            pollSettingsCard: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Share Drawer //
            shareViaAppButton: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Verify Phone Number //
            phoneNumberCardBackground: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            verificationCodeCardBackground: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            phoneNumberInputShadow: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            imageWithShadowContainer: {
                web: { boxShadow: "" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOffset: {
                        width: 0,
                        height: 5,
                    },
                    shadowOpacity: 0.34,
                    shadowRadius: 6.27,
                    elevation: 5,
                },
            },
            errorBoxShadow: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.black,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
        },
        cornerRadius: {
            // Bottom Navigation //
            bottomNavigationCard: 20,
            bottomNavigationFlagBackground: 7,

            // Example Poll Card //
            examplePollCard: 10,

            // Input //
            cta: 8,

            // CTAs //
            input: 8,

            // Option Card //
            optionCard: 13,

            // Poll Default Settings Card //
            profileSettingsCard: 8,

            // Modal //
            modal: 20,

            // Drawer //
            drawer: 40,

            // Poll Send Settings //
            pollSettingsCard: 8,

            // Verify Phone Number //
            phoneNumberCardBackground: 8,
            verificationCodeCardBackground: 8,
        },
        // to be removed
        borderRadius: {
            button: 8,
            modal: 20,
            errorModal: 8,
            bottomNav: 21,
            CTA: 10,
            pills: 50,
            pollSendSetting: 8,
            drawer: 40,
            input: 8,
        },
        //
        image: {
            // App //
            appLogo: require("@assets/images/Icon-1.png"),
            previewImageBackground: require("@assets/images/preview-image/white-background.png"),
            previewImageCard: require("@assets/images/preview-image/card.png"),
            appBackgroundDesktop: "linear-gradient(180deg, #84CFFF, #4DA1FF)",
            appBackgroundMobile: "#fff",
            closeX: Icons.dismiss,
            linkOut: "default/linkOut.svg",
            splashImage: require("@assets/images/splash.png"),
            homeScreenImage: require("@assets/images/homescreen6x.png"),
            consentScreenImage: require("@assets/images/consent.png"),
            backIcon: Icons.back,
            checkboxChecked: Icons.checkboxChecked,
            checkboxUnchecked: Icons.checkboxUnchecked,
            pollHistoryHeader: require("@assets/images/PollHistoryIcon.png"),
            pollHistoryEmpty: require("@assets/images/Polygon.png"),
            imagePlaceholder: EncodedImages.imagePlaceholder,

            // Bottom Navigation //
            navBarTrash: Icons.discard,
            navBarProfile: Icons.profile,
            navBarEdit: Icons.edit,
            navBarHome: Icons.home,
            navBarShare: Icons.share,
            navBarInfo: Icons.info,

            // Example Poll Card //
            examplePollSuggestionButton:
                "default/examplePollSuggestionButton.svg",
            examplePollAddButton: "default/examplePollAddButton.svg",
            examplePollDateAndTime: "default/examplePollDateAndTime.svg",

            // Option Card //
            optionCardVoteCounterActive:
                "default/optionCardVoteCounterActive.svg",
            optionCardVoteCounterInactive:
                "default/optionCardVoteCounterInactive.svg",
            optionCardLocked: Icons.optionLocked,
            optionCardRemove: Icons.optionRemove,
            optionCardLink: "default/optionCardLink.svg",
            optionCardAddedConfirmation:
                "default/optionCardAddedConfirmation.svg",
            optionCardCancel: "default/optionCardCancel.svg",
            optionCardDrag: Icons.group,

            // Profile //
            userNameEdit: Icons.edit,
            profileAvatar: Icons.profile,
            profileAuthenticateButtonIcon: Icons.phone,
            profilePhoneNumberIcon: Icons.phone,
            profileLinkOutIcon: Icons.linkOut,
            profileShareFeedbackIcon: Icons.share,
            userSettingsEditPoll: Icons.lock,
            userSettingsMultiVote: Icons.checkDouble,
            userSettingsSeeWhoVoted: Icons.eye,

            // Modal/Drawer //
            pollSendSettingsGraphic: EncodedImages.controls,
            pollLockedGraphic: EncodedImages.locked,
            addUserNameGraphic: EncodedImages.helloRafiki,
            loadPollFailedGraphic: EncodedImages.internetConnectionError,
            launchPollFailedGraphic: EncodedImages.internetConnectionError,
            updatePollFailedGraphic: EncodedImages.internetConnectionError,
            updatePollCrossErrorGraphic: EncodedImages.crossError,
            missingOptionsGraphic: EncodedImages.building,
            pollAnonymousGraphic: "default/pollAnonymousGraphic.svg",
            shareVoteGraphic: EncodedImages.voteShare,
            userAuthenticateGraphic: EncodedImages.phoneVerification,
            pollsAdvertise: EncodedImages.pollsAdvertise,
            rememberSubmit: EncodedImages.rememberSubmit,

            // Share Icons //
            shareViaSMS: require("@assets/images/sms.png"),
            shareViaEmail: require("@assets/images/Email.png"),
            shareViaMessenger: require("@assets/images/messanger.png"),
            shareViaWhatsApp: require("@assets/images/Whatsapp.png"),
            shareViaIMessages: require("@assets/images/iMessage.png"),
            shareCopyButtonCheckmark: Icons.checkSingle,

            // Get App Screen
            getAppScreenImage: EncodedImages.phoneVerification,
        },

        layout: {
            palette: [
                "#008DC3",
                "#2CAFD9",
                "#42C0E4",
                "#3FCDE4",
                "#26C9D9",
                "#21ABA4",
                "#29D4B6",
                "#1C575F",
                "#023E8A",
                "#0077B6",
            ],
            padding: {
                phoneButton: 55,
                cta: {
                    padding: 10,
                },
            },
            margin: {
                sectionTitle: {
                    top: 20,
                    bottom: 10,
                },
            },
            font: {
                section: {
                    fontFamily: fontPalette.bold,
                    fontSize: fontSizeMultiplier * 21,
                    lineHeight: lineHeightMultiplier * 21,
                    color: colorPalette.black,
                },
                title: {
                    fontFamily: fontPalette.regular,
                    fontSize: fontSizeMultiplier * 21,
                    lineHeight: lineHeightMultiplier * 21,
                    color: colorPalette.black,
                },
                subtitle: {
                    fontFamily: fontPalette.regular,
                    fontSize: fontSizeMultiplier * 16,
                    lineHeight: lineHeightMultiplier * 16,
                    color: colorPalette.black,
                },
                ctaPrimaryIcon: {
                    fontFamily: fontPalette.bold,
                    fontSize: fontSizeMultiplier * 20,
                    lineHeight: lineHeightMultiplier * 20,
                },
                ctaSecondaryIcon: {
                    fontFamily: fontPalette.bold,
                    fontSize: fontSizeMultiplier * 18,
                    lineHeight: lineHeightMultiplier * 18,
                },
                ctaFloatingTextIcon: {
                    fontFamily: fontPalette.bold,
                    fontSize: fontSizeMultiplier * 14,
                    lineHeight: lineHeightMultiplier * 14,
                },
            },

            appCardDesktopRadius: 41,
            appCardDesktopWidth: 576,
            appCardMobileWidth: 320,
            defaultOptionCardHeight: 62,
            profileAvatarCircle: 95,
            smallProfileAvatarCircle: 35,
            modalOverlayOpacity: 0.75,
        },
        lottie: {
            checkmark: require("@assets/lottie/checkmark.json"),
            checkmarkDone: require("@assets/lottie/checkmark_green.json"),
            checkBox: require("@assets/lottie/checkBox.json"),
            whiteSpinner: require("@assets/lottie/WhiteLoadingSpinner.json"),
            spinner: require("@assets/lottie/spinner.json"),
            trashCan: require("@assets/lottie/trashCan.json"),
            createPollSuccess: require("@assets/lottie/createPollSuccess.json"),
            signInSuccess: require("@assets/lottie/signInSuccess.json"),
            voteSuccess: require("@assets/lottie/voteSuccess.json"),
            success: require("@assets/lottie/voteSuccess.json"),
        },
    }

    // apply the overrides on top of the generated theme

    return {
        color: {
            ...theme.color,
            ...overrides.color,
        },
        font: {
            ...theme.font,
            ...overrides.font,
        },
        shadow: {
            ...theme.shadow,
            ...overrides.shadow,
        },
        cornerRadius: {
            ...theme.cornerRadius,
            ...overrides.cornerRadius,
        },
        image: {
            ...theme.image,
            ...overrides.image,
        },
        layout: {
            ...theme.layout,
            ...overrides.layout,
        },
        lottie: {
            ...theme.lottie,
            ...overrides.lottie,
        },

        //old stuff to be removed
        borderRadius: {
            ...theme.borderRadius,
            ...overrides.borderRadius,
        },
        fontFiles: {
            ...theme.fontFiles,
            ...overrides.fontFiles,
        },
    }
}

// create the default theme

const fontPalette: FontPalette = {
    regular: "sf-pro-display",
    semiBold: "sf-pro-display-semibold",
    bold: "sf-pro-display-bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included
    IcoMoon: require("@assets/icons/icomoon.ttf"),
}

export const DefaultTheme: Theme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: defaultColorPalette,
    fontSizeMultiplier: 1,
    lineHeightMultiplier: 1,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie
        //old stuff to be removed
        // borderRadius
        // fontFiles
    },
})

export const TenantTheme = DefaultTheme

export const getThemeByTenantName = (tenantName: string): Theme => {
    return TenantTheme
}
