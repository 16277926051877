import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { ImageStyle, PixelRatio, TextStyle, ViewStyle } from "react-native"

export interface VoteScreenListItemStyles extends Styles {
    wrapper: ViewStyle
    screenShotContainer: ViewStyle
    container: ViewStyle
    logoContainer: ViewStyle
    headerContainer: ViewStyle
    logo: ImageStyle
    header: TextStyle
    listContainer: ViewStyle
    imageBackground: ViewStyle
}

export const PreviewImageStyles: StyleFunction<VoteScreenListItemStyles> = ({
    theme,
}) => {
    const ratio = PixelRatio.get()
    const previewImageWidth = 932 / ratio
    const listMargin = (theme.image.previewImageBackground ? 25 : 0) / ratio
    const cardHeight = 200 / ratio
    const headerHeight =
        (theme.image.previewImageBackground ? 150 : 130) / ratio
    const previewImageHeight = cardHeight * 3 + listMargin + headerHeight

    return {
        wrapper: {
            overflow: "hidden",
        },
        screenShotContainer: {
            position: "absolute",
            bottom: -previewImageHeight - headerHeight,
        },
        container: {
            width: previewImageWidth,
            height: previewImageHeight,
            backgroundColor: "#fff",
        },
        imageBackground: {
            width: previewImageWidth,
            height: previewImageHeight,
        },
        logoContainer: {
            position: "absolute",
            left: 50 / ratio,
            height: headerHeight,
            justifyContent: "center",
        },
        headerContainer: {
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            height: headerHeight,
            width: 788 / ratio,
            paddingHorizontal: 100 / ratio,
        },
        logo: {
            width: 100 / ratio,
            height: 100 / ratio,
        },
        header: {
            ...theme.font.questionTitle,
            fontFamily: theme.font.questionTitle.fontFamily,
            lineHeight: theme.font.questionTitle.lineHeight / ratio,
            fontSize: theme.font.questionTitle.fontSize / ratio,
            textAlign: "center",
        },
        listContainer: {
            marginTop: listMargin,
        },
    }
}
