import { Button, FormError, Input } from "@components"
import { DrawerContext, NavigationContext, UserContext } from "@contexts"
import { useFocus } from "@helpers/hooks/useFocus"
import useStyles from "@helpers/hooks/useStyles"
import formatPhoneNumber from "@helpers/phoneNumberFormatter"
import { confirmUser } from "@services/amplify"
import { AnalyticsEvent } from "@services/analytics"
import Logger from "@services/logger"
import { SignInFlow } from "@types"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Image, Text, TextInput } from "react-native"
import { ResendCode } from "../ResendCode"
import { sectionStyles } from "./EnterSMSCode.styles"
import { isSafari } from "react-device-detect"
import { isPlatformNative } from "@helpers/isPlatformNative"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"

interface EnterSMSCoderProps {
    signInFlow: SignInFlow
}

const EnterSMSCode = ({ signInFlow }: EnterSMSCoderProps) => {
    const { styles, theme } = useStyles(sectionStyles)
    const { t } = useTranslation()
    const [code, setCode] = useState("")
    const codeInputRef = useRef<TextInput>()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const {
        userState: { phoneNumber, cognitoUser, isPhoneNumberEntered },
        userDispatch,
    } = useContext(UserContext)
    const { drawerDispatch } = useContext(DrawerContext)
    const { navigate } = useContext(NavigationContext)

    const { isMobileView } = useScreenDimensions()

    const { setFocusTarget, setFocus } = useFocus()

    const handleConfirm = async () => {
        if (cognitoUser?.challengeName !== "CUSTOM_CHALLENGE" || !cognitoUser) {
            Logger.info(JSON.stringify(cognitoUser))
            return
        }

        setIsLoading(true)

        await confirmUser({
            cognitoUser,
            smsCode: code,
            drawerDispatch,
            userDispatch,
            setErrorMessage,
            t,
            navigate,
            signInFlow,
            successAnimation: theme.lottie.signInSuccess,
        })

        setIsLoading(false)
    }

    const isValidCode = code.length >= 4

    const handleCodeChange = (val: string) => {
        setCode(val.substring(0, 7).replace(/[^0-9]/g, ""))
        if (val.length >= 4 && !isSafari && !isPlatformNative()) {
            setTimeout(setFocus, 400, "smsCode")
        }
    }

    useEffect(() => {
        if (isPhoneNumberEntered && codeInputRef.current && !isMobileView) {
            setFocusTarget("smsCode", codeInputRef.current, "number-pad")
            setTimeout(setFocus, 400, "smsCode")
        }
    }, [codeInputRef, isPhoneNumberEntered])

    const submitCodeAnalyticsEvent = useMemo(() => {
        switch (signInFlow) {
            case SignInFlow.onboarding:
                return AnalyticsEvent.tapOnboardSubmitCode
            case SignInFlow.launchPoll:
                return AnalyticsEvent.tapLaunchSubmitCode
            case SignInFlow.profile:
                return AnalyticsEvent.tapProfileSubmitCode
            case SignInFlow.editPoll:
                return AnalyticsEvent.tapEditSubmitCode
            default:
                return undefined
        }
    }, [signInFlow])

    return (
        <>
            <Image
                source={{
                    uri: theme.image.userAuthenticateGraphic,
                }}
                resizeMode={"contain"}
                style={styles.image}
            />
            <Text style={styles.title}>
                {t("verificationCodeModal.enterCode")}
            </Text>
            <Text style={styles.subtitle}>
                {t("verificationCodeModal.enterTheCodeThatWasSentTo")}
            </Text>
            <Text style={styles.phoneNumber}>
                {formatPhoneNumber(phoneNumber ?? "")}
            </Text>
            <ResendCode setErrorMessage={setErrorMessage} />
            <Input
                ref={codeInputRef as any}
                style={styles.input}
                inputStyle={styles.inputText}
                value={code}
                variant="box"
                onChange={handleCodeChange}
                keyboardType="number-pad"
                placeholder="0000"
                importantForAutofill={true}
            />
            {!!errorMessage && (
                <FormError
                    message={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <Button
                titleStyle={styles.btnTitle}
                disabled={!isValidCode}
                onPress={handleConfirm}
                isLoading={isLoading}
                title={t("submit")}
                analyticsEvent={submitCodeAnalyticsEvent}
            />
        </>
    )
}

export default EnterSMSCode
