import { StyleFunction, Styles } from "@helpers/hooks/useStyles"
import { TextStyle, ViewStyle } from "react-native"

export interface PollListHeaderStyles extends Styles {
    container: ViewStyle
    bottomShadowContainer: ViewStyle
    containerShadow: ViewStyle
    divider: ViewStyle
    title: TextStyle
    subTitleContainer: ViewStyle
    subTitle: TextStyle
    subTitle2: TextStyle
    tapToTypeButtonContainer: ViewStyle
    tapToTypeButtonTitle: TextStyle
}

export const pollListHeaderStyles: StyleFunction<PollListHeaderStyles> = ({
    theme,
}) => {
    return {
        container: {
            width: "100%",
            zIndex: 10,
        },
        bottomShadowContainer: {
            width: "100%",
            overflow: "hidden",
            paddingBottom: 10,
            marginBottom: -4,
        },
        containerShadow: {},
        contentContainer: {
            alignItems: "center",
        },
        divider: {
            marginHorizontal: 40,
            height: 2,
            borderRadius: 30,
            backgroundColor: theme.color.divider,
        },
        title: {
            ...theme.font.createPollTitleNative,
            maxHeight: 58,
        },
        subTitleContainer: {
            position: "absolute",
            top: "100%",
            alignSelf: "center",
            alignItems: "center",
            paddingVertical: 24,
        },
        subTitle: {
            ...theme.font.helpTextPrimary,
        },
        subTitle2: {
            ...theme.font.helpTextSecondary,
        },
        tapToTypeButtonContainer: {
            width: 264,
            height: 36,
            marginVertical: 7,
        },
        tapToTypeButtonTitle: {
            height: 21,
            lineHeight: 21,
        },
        MOBILE: {
            containerShadow: {
                ...theme.shadow.pollHeader.web,
            },
        },
        WEB: {
            containerShadow: {
                ...theme.shadow.pollHeader.web,
            },
            contentContainer: {
                paddingHorizontal: 110,
            },
            bottomShadowContainer: {
                marginTop: 30,
            },
            divider: {
                marginHorizontal: 140,
            },
            title: {
                ...theme.font.createPollTitleWeb,
            },
        },
        NATIVE: {
            containerShadow: {
                backgroundColor: theme.color.titleContainer,
                ...theme.shadow.pollHeader.native,
            },
        },
    }
}
