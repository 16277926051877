import {
    FontPalette,
    FontFiles,
    createTheme,
    ColorPalette,
    Theme,
    DefaultTheme,
    defaultColorPalette,
} from "@assets/theme/Theme"
import { EncodedImages } from "../images/encodedImages"

// create the default theme

const fontPalette: FontPalette = {
    regular: "polls-poppins-regular",
    semiBold: "polls-poppins-semiBold",
    bold: "polls-poppins-bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included

    // add custom fonts
    "polls-poppins-regular": require("../fonts/Poppins/Poppins-Regular.ttf"),
    "polls-poppins-semiBold": require("../fonts/Poppins/Poppins-SemiBold.ttf"),
    "polls-poppins-bold": require("../fonts/Poppins/Poppins-Bold.ttf"),

    // set custom icon font, or include default
    // default
    IcoMoon: require("@assets/icons/icomoon.ttf"),

    // custom
    // IcoMoon: require("../icons/icomoon.ttf"),
}

const colorPalette: ColorPalette = {
    ...defaultColorPalette,
    
    primary: "#007AFE",
    secondary: "#0286FF",
    success: "#05CB4F",
    primaryText: "#292929",
    secondaryText: "#7B7B7B",
    background: "#E0EAFD",

    black: "#000000",
    white: "#FFFFFF",
    red: "#D0021B",
    blue: "#007AFE",
    darkGrey: "#9B9B9B",
    grey: "#D8D8D8",
    lightGrey: "#EBEBEB",
    flag: "#FA6400",
    textColor: "#535C71",
    lightBlue: "#EFF5FF",

    // Polls Colors
    pollPrimaryTextColor: "#343E57",

}

export const TenantTheme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: colorPalette,
    fontSizeMultiplier: 1.0,
    lineHeightMultiplier: 1.0,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie

        //old stuff to be removed
        // borderRadius
        // fontFiles

        color: {
            navHeaderBackground: colorPalette.white,
            optionCardProgressBarFill: colorPalette.blue,
            bottomNavigationFlagBackground: "#7ED321",
        },

        font: {
            pollTitleActiveWeb: {
                ...DefaultTheme.font.pollTitleActiveWeb,
                fontFamily: fontPalette.bold,
                fontSize: 24,
                lineHeight: 30,
                color: colorPalette.primaryText,
            },
            questionTitle: {
                ...DefaultTheme.font.questionTitle,
                fontFamily: fontPalette.bold,
                color: colorPalette.pollPrimaryTextColor,
                lineHeight: 44,
                fontSize: 34,
            },
            defaultCardTemplateTitle: {
                ...DefaultTheme.font.defaultCardTemplateTitle,
                fontFamily: fontPalette.semiBold,
                fontSize: 16,
                lineHeight: 17.25,
            },
            defaultCardTemplateSubtitle: {
                ...DefaultTheme.font.defaultCardTemplateSubtitle,
                fontFamily: fontPalette.regular,
                fontSize: 11,
                lineHeight: 14,
                color: colorPalette.black,
            },
            defaultCardTemplateDescription: {
                ...DefaultTheme.font.defaultCardTemplateDescription,
                fontFamily: fontPalette.semiBold,
                fontSize: 11,
                lineHeight: 14,
                color: colorPalette.darkGrey,
            },
            optionTitle: {
                ...DefaultTheme.font.optionTitle,
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primaryText,
                fontSize: 32,
                lineHeight: 42,
            },
            optionSubtitle: {
                ...DefaultTheme.font.optionSubtitle,
                fontFamily: fontPalette.regular,
                color: colorPalette.secondaryText,
                fontSize: 28,
                lineHeight: 36,
            },
            optionCardTextVoteCount: {
                ...DefaultTheme.font.optionCardTextVoteCount,
                fontFamily: fontPalette.semiBold,
                fontSize: 10,
                lineHeight: 10,
            },
            defaultCardTemplateLink: {
                ...DefaultTheme.font.defaultCardTemplateLink,
                fontFamily: fontPalette.semiBold,
                fontSize: 10,
                lineHeight: 10,
            },
            ctaPrimaryText: {
                ...DefaultTheme.font.ctaPrimaryText,
                fontFamily: fontPalette.semiBold,
            },
        },

        image: {
            appLogo: require("../images/pinktadaLogo.png"), // top left corner of desktop
            appBackgroundDesktop: `url(${EncodedImages.background})`,
            previewImageBackground: null,
            previewImageLogo: EncodedImages.logo,
        },

        // lottie: {
        //     checkmark: require("../lottie/checkmark.json"),
        //     checkmarkDone: require("../lottie/checkmark_green.json"),
        //     whiteSpinner: require("../lottie/WhiteLoadingSpinner.json"),
        //     spinner: require("../lottie/spinner.json"),
        //     trashCan: require("../lottie/trashCan.json"),
        // },
    },
})

export const getThemeByTenantName = (tenantName: string): Theme => {
    return TenantTheme
}
