import { RequestPollOption } from "./getRequestPoll"
import { isPlatformNative } from "@helpers/isPlatformNative"

export enum EmbeddedMessageEvent {
    VIEW_OPTION_DETAILS = "viewOptionDetails",
    ANALYTICS_EVENT = "analyticsEvent",
}

export type EmbeddedMessage =
    | {
          event: EmbeddedMessageEvent.VIEW_OPTION_DETAILS
          payload: {
              option: RequestPollOption
          }
      }
    | {
          event: EmbeddedMessageEvent.ANALYTICS_EVENT
          payload: {
              event: string
              parameters?: Record<string, string | number | boolean | undefined>
          }
      }

export const sendWrapperMessage = (message: EmbeddedMessage) => {
    if (isPlatformNative()) return
    // @ts-ignore
    if (window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify(message))
    } else {
        window.postMessage(JSON.stringify(message), "*")
    }
}
