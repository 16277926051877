import React, { useContext, useEffect } from "react"
import {
    AuthModal,
    BackButton,
    BaseInput,
    Button,
    ConfirmLogoutModal,
    DiscardModal,
    EditNameModal,
    Input,
    ScreenLayout,
    SeeWhoVotedModal,
    SendPollSettingsModal,
    ShareVoteModal,
    Title,
    VoterAddNameModal,
} from "@components"
import { commonStyles } from "@styles/Main.styles"
import { Icons } from "@assets/icons"
import useStyles from "@helpers/hooks/useStyles"
import { DrawerActionType, DrawerContext } from "@contexts"
import { SignInFlow } from "@types"
import { ScrollView } from "react-native-gesture-handler"
import { t } from "i18next"
import { noop } from "lodash"

import { ModalName } from "@components/Drawer/content"
import { CreateOwnPolls } from "@components/Drawer/content/CreateOwnPolls"
import { EncodedImages } from "@assets/images/encodedImages"

const ThemeScreen = () => {
    const { styles, theme } = useStyles(commonStyles)
    const { drawerDispatch } = useContext(DrawerContext)

    const handlePress = () => {
        console.log("button press")
    }

    const openAuthDrawerOne = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <AuthModal signInFlow={SignInFlow.profile} />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.SIGN_IN_PROFILE,
            },
        })
    }

    const openDiscardDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <DiscardModal />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: false,
                identifier: ModalName.CONFIRM_DISCARD_MODAL,
            },
        })
    }

    const openLogoutDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <ConfirmLogoutModal />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: false,
                delay: 100,
                identifier: ModalName.CONFIRM_LOGOUT_MODAL,
            },
        })
    }

    const openEditNameDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <EditNameModal />,
                dismissible: true,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.EDIT_NAME_MODAL,
            },
        })
    }

    const openSeeWhoVotedDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: (
                    <SeeWhoVotedModal
                        optionId="123"
                        checked
                        totalCounter={0}
                        voteCount={0}
                        title={""}
                    />
                ),
                dismissible: true,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.SEE_WHO_VOTED_MODAL,
            },
        })
    }

    const openSendPollSettingsDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <SendPollSettingsModal />,
                dismissible: true,
                hasCloseButton: true,
                hasBackground: true,
                identifier: ModalName.SEND_POLL_SETTINGS,
            },
        })
    }

    const openShareVoteDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <ShareVoteModal />,
                dismissible: true,
                hasCloseButton: true,
                hasBackground: true,
                identifier: ModalName.SHARE_VOTE,
            },
        })
    }

    const openVoterAddNameDrawer = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <VoterAddNameModal />,
                dismissible: false,
                hasCloseButton: false,
                hasBackground: true,
                identifier: ModalName.VOTE_ENTER_NAME,
            },
        })
    }

    const showSuccessModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: { title: "No delay" },
        })
    }

    const showSuccessModalWithDelay = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: { title: "Delayed", delay: 2000 },
        })
    }

    const showCustomModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_MODAL,
            payload: {
                title: "Delayed",
                message: "this modal should stay until dismissed by user",
                delay: 100,
                identifier: ModalName.DELAYED_MODAL,
            },
        })
    }

    const showModalPollLocked = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_MODAL,
            payload: {
                title: t("pollLocked"),
                message: t("voteScreen.pollLocked"),
                image: theme.image.pollLockedGraphic,
                imageSize: {
                    height: 140,
                    width: 160,
                },
                buttonTitle: t("okay"),
                dismissible: true,
                identifier: ModalName.POLL_LOCKED,
            },
        })
    }

    const showModalSignIn = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_MODAL,
            payload: {
                title: t("signIn"),
                message: t("voteScreen.signInRequired"),
                image: theme.image.pollLockedGraphic,
                imageSize: {
                    height: 140,
                    width: 160,
                },
                buttonTitle: t("signIn"),
                onAction: () => noop,
                dismissible: true,
                identifier: ModalName.SIGN_IN_EDIT,
            },
        })
    }

    const showModalOops = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_MODAL,
            payload: {
                title: t("oops"),
                message:
                    t("launchPoll.couldntLaunchThePoll") +
                    " " +
                    t("launchPoll.checkConnection"),
                image: theme.image.launchPollFailedGraphic,
                imageSize: {
                    height: 105,
                    width: 131,
                },
                buttonTitle: t("okay"),
                delay: 0,
                identifier: ModalName.FAILED_TO_LAUNCH_POLL,
            },
        })
    }

    const showAlmostThereModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_MODAL,
            payload: {
                title: t("createPollScreen.invalidPollModal.almostThere"),
                message: t("createPollScreen.invalidPollModal.pollNeedsTwo"),
                buttonTitle: t("okay"),
                image: theme.image.missingOptionsGraphic,
                imageSize: {
                    width: 160,
                    height: 160,
                },
                identifier: ModalName.INVALID_POLL_MODAL,
            },
        })
    }

    const showPollDeletedModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: {
                title: t("pollDeleted"),
                message: t("startPoll"),
                animation: theme.lottie.trashCan,
            },
        })
    }

    const openCreateOwnPolls = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_DRAWER,
            payload: {
                content: <CreateOwnPolls />,
                hasBackground: true,
                dismissible: true,
                hasCloseButton: true,
                isModal: true,
                identifier: ModalName.SHARE_VOTE,
            },
        })
    }

    const openVoteSuccessModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: {
                title: "Vote success",
                animation: theme.lottie.voteSuccess,
                duration: 1500,
            },
        })
    }

    const openCreatePollSuccessModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: {
                title: "Create Poll success",
                animation: theme.lottie.createPollSuccess,
                duration: 1500,
                animationEnd: 0.95,
            },
        })
    }

    const openSignInSuccessModal = () => {
        drawerDispatch({
            type: DrawerActionType.SHOW_SUCCESS_MODAL,
            payload: {
                title: "Sign in success",
                animation: theme.lottie.signInSuccess,
                duration: 1500,
                animationStart: 0.38,
                animationScale: 2,
            },
        })
    }

    return (
        <ScreenLayout>
            <ScrollView>
                <Title style={styles.section} title="Text variations" />
                <Title style={styles.section} title="Section" />
                <Title style={styles.sectionTitle} title="Profile Section" />
                <Title style={styles.title} title="Default Title" />
                <Title style={styles.subtitle} title="Default Subtitle" />
                <Title
                    style={styles.subtitle}
                    color={"#ff0000"}
                    title="Red Subtitle"
                />

                <Title style={styles.section} title="BaseInput variations" />
                <BaseInput />
                <BaseInput placeholder="BaseInput" />
                <BaseInput placeholder="Placeholder" style={styles.input} />
                <BaseInput
                    variant="box"
                    placeholder="box"
                    style={styles.input}
                />

                <Title style={styles.section} title="Input variations" />

                <Input placeholder="Input" style={styles.input} />
                <Input
                    variant="box"
                    placeholder="input box"
                    multiline={true}
                    numberOfLines={2}
                    style={styles.input}
                />

                <Title style={styles.section} title="Button variations" />
                <Button
                    variant={"primary"}
                    title={"Primary disabled"}
                    disabled
                    onPress={handlePress}
                />
                <Button
                    variant={"primary"}
                    title={"Primary loading"}
                    isLoading
                    onPress={handlePress}
                />
                <Button
                    variant={"primary"}
                    title={"Primary with Icon on the Left"}
                    iconLeft={Icons.phone}
                    onPress={handlePress}
                />
                <Button
                    variant={"primary"}
                    title={"Primary with Icon on the Right"}
                    iconRight={Icons.phone}
                    onPress={handlePress}
                />
                <Button
                    variant={"primary"}
                    title={"Primary with both Icons"}
                    iconRight={Icons.phone}
                    iconLeft={Icons.phone}
                    onPress={handlePress}
                />

                <Button
                    variant={"secondary"}
                    title={"Secondary button"}
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Secondary disabled"}
                    disabled
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Secondary loading"}
                    isLoading
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Secondary with Icon on the Left"}
                    iconLeft={Icons.phone}
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Secondary with Icon on the Right"}
                    iconRight={Icons.phone}
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Secondary with both Icons"}
                    iconRight={Icons.phone}
                    iconLeft={Icons.phone}
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Secondary with custom font color"}
                    titleStyle={{ color: "#363636" }}
                    onPress={handlePress}
                />

                <Button
                    variant={"secondary"}
                    title={"Cancel"}
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Logout"}
                    titleStyle={{ color: "#ff0000" }}
                    onPress={handlePress}
                />
                <Button
                    variant={"secondary"}
                    title={"Custom font and bg"}
                    titleStyle={{ color: "#00ff00" }}
                    style={{ backgroundColor: "#FF00FF" }}
                    onPress={handlePress}
                />
                <Button
                    variant={"text"}
                    title={"Text Button"}
                    onPress={handlePress}
                />
                <Button
                    variant={"text"}
                    title={"Text Button Disabled"}
                    disabled
                    onPress={handlePress}
                />
                <Button
                    variant={"text"}
                    title={"Text"}
                    iconLeft={Icons.phone}
                    onPress={handlePress}
                />
                <Title
                    style={styles.subtitle}
                    title="back button reconstruction"
                />
                <BackButton onPress={handlePress} />
                <Title
                    style={styles.subtitle}
                    title="Phone button reconstruction"
                />
                <Button
                    variant={"secondary"}
                    title={"Sign in with Phone"}
                    style={{ paddingHorizontal: 40, minHeight: 38 }}
                    titleStyle={{
                        ...theme.font.backButtonText,
                        color: theme.color.helpTextPrimary,
                    }}
                    iconStyle={{
                        fontSize: 20,
                        lineHeight: 20,
                        paddingRight: 15,
                    }}
                    iconLeft={Icons.phone}
                    onPress={handlePress}
                />
                <BackButton onPress={handlePress} />
                <Title style={styles.section} title="Modals/Drawers" />
                <Button title={"AuthModal Phone"} onPress={openAuthDrawerOne} />
                <Button
                    title={"Show Success Modal"}
                    onPress={showSuccessModal}
                />
                <Button
                    title={"Delayed Success Modal"}
                    onPress={showSuccessModalWithDelay}
                />
                <Button title={"Custom Modal"} onPress={showCustomModal} />
                <Button
                    title={"Show Sign In Modal"}
                    onPress={showModalSignIn}
                />
                <Button
                    title={"Show Poll Locked Modal"}
                    onPress={showModalPollLocked}
                />
                <Button title={"Show Ooops Modal"} onPress={showModalOops} />
                <Button
                    title={"Show Almost There Modal"}
                    onPress={showAlmostThereModal}
                />
                <Button
                    title={"Show Poll Deleted Modal"}
                    onPress={showPollDeletedModal}
                />
                {/*drawers*/}
                <Button title={"Logout"} onPress={openLogoutDrawer} />
                <Button title={"Discard"} onPress={openDiscardDrawer} />
                <Button title={"Edit Name"} onPress={openEditNameDrawer} />
                <Button
                    title={"See Who Voted"}
                    onPress={openSeeWhoVotedDrawer}
                />
                <Button
                    title={"Send Poll Settings"}
                    onPress={openSendPollSettingsDrawer}
                />
                <Button title={"Share Vote"} onPress={openShareVoteDrawer} />
                <Button
                    title={"Voter Add Name"}
                    onPress={openVoterAddNameDrawer}
                />
                <Button
                    title={"Create Own Polls"}
                    onPress={openCreateOwnPolls}
                />
                <Button
                    title="Show create poll success modal"
                    onPress={openCreatePollSuccessModal}
                />
                <Button
                    title="Show vote success modal"
                    onPress={openVoteSuccessModal}
                />
                <Button
                    title="Show sign in success modal"
                    onPress={openSignInSuccessModal}
                />
            </ScrollView>
        </ScreenLayout>
    )
}

export default ThemeScreen
