import { ProfileScreen } from "./ProfileScreen"
import { CreatePollScreen } from "./CreatePollScreen"
import { VoteScreen } from "./VoteScreen"
import { ThemeScreen } from "./ThemeScreen"
import { SignInScreen } from "./SignInScreen"
import { ConsentScreen } from "./ConsentScreen"
import { HomeScreen } from "./HomeScreen"
import { ConfirmCodeScreen } from "./ConfirmCodeScreen"
import { GetAppScreen } from "./GetAppScreen"
import { SelectPollScreen } from "./SelectPollScreen"

export enum ScreenName {
    PROFILE_SCREEN = "profileScreen",
    PROFILE_SCREEN_SIGN_IN = "profileScreenSignIn",
    VOTE_SCREEN = "voteScreen",
    CREATE_POLL_SCREEN = "createPollScreen",
    THEME_SCREEN = "themeScreen",
    CONSENT_SCREEN = "consentScreen",
    HOME_SCREEN = "homeScreen",
    SIGN_IN_SCREEN = "signInScreen",
    SIGN_IN_SCREEN_CONFIRM = "signInScreenConfirm",
    GET_APP_SCREEN = "getAppScreen",
    SELECT_POLL_SCREEN = "selectPollScreen",
}

export {
    ProfileScreen,
    VoteScreen,
    CreatePollScreen,
    ThemeScreen,
    ConsentScreen,
    HomeScreen,
    SignInScreen,
    ConfirmCodeScreen,
    GetAppScreen,
    SelectPollScreen,
}
